@import './src/styles/variables/fonts';

/*********************************************************/
/***********************  MIXINS  ************************/
/*********************************************************/

/*********************  Functions  ***********************/

@function getRem($arg: 10) {
  $rem: 0.1;

  @return #{$arg * $rem}rem;
}

/***********************  Common  ************************/

@mixin standard-block-margin($marginTop: 0, $marginX: 0) {
  @media screen and (min-width: 1023.9px) {
    margin: $marginTop $marginX getRem(104);
  }
  @media screen and (max-width: 1024px) {
    margin: $marginTop $marginX getRem(64);
  }
  &:last-child {
    margin-bottom: 0;
  }
}

@mixin overflow-x-scroll-section-indent(
  $marginLeft: -1.6rem,
  $marginRight: -1.6rem,
  $paddingLeft: 1.6rem,
  $paddingRight: 1.6rem
) {
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
  margin-right: $marginRight;
  margin-left: $marginLeft;
}

@mixin flex($jc: center, $ai: center) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
}

@mixin rounded($value: 0) {
  border-radius: getRem($value);
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin lines-ellipsis($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/***********************  Indents  ***********************/

@mixin indent(
  $indent: padding,
  $top: 0,
  $right: false,
  $bottom: false,
  $left: false
) {
  @if (not $right and not $bottom and not $right) {
    #{$indent}: getRem($top);
  } @else if (not $bottom and not $left) {
    #{$indent}: getRem($top) getRem($right);
  } @else if (not $left) {
    #{$indent}: getRem($top) getRem($right) getRem($bottom);
  } @else {
    #{$indent}: getRem($top) getRem($right) getRem($bottom) getRem($left);
  }
}

/************************  Fonts  ************************/

@mixin text-bold() {
  @include font-codec(bold, 24, 32);
  font-weight: bold;
  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 18, 24);
  }
}

@mixin text-bold-s() {
  @include font-codec(bold, 18, 24);
  font-weight: bold;
  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 16, 20);
  }
}

@mixin text-regular() {
  @include font-codec(Regular, 24, 32);
  font-weight: normal;
  @media screen and (max-width: 1023px) {
    @include font-codec(Regular, 18, 24);
  }
}

@mixin text-regular-s() {
  @include font-codec(Regular, 18, 24);
  font-weight: normal;
  @media screen and (max-width: 1023px) {
    @include font-codec(Regular, 16, 20);
  }
}

@mixin borderModalCard-M($radiusTop: 0, $radiusBottom: 2rem) {
  border-radius: $radiusTop $radiusTop $radiusBottom $radiusBottom;
}

@mixin title-L($color: #000) {
  font-family: 'CodecPro-Bold', sans-serif;
  font-style: normal;
  font-size: 6.4rem;
  line-height: 6.4rem;
}

@mixin title-M($color: #000) {
  font-family: 'CodecPro-Bold', sans-serif;
  font-style: normal;
  font-size: 4rem;
  line-height: 4rem;
}

@mixin desktop() {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin tablets() {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones() {
  @media screen and (max-width: 575px) {
    @content;
  }
}
