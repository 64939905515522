/*********************************************************/
/******************  FONTS VARIABLES  ********************/
/*********************************************************/

$font-codec-pro-path: '~/public/fonts';
$codec-pro-regular: 'CodecPro-Regular';
$codec-pro-bold: 'CodecPro-Bold';

@font-face {
  font-family: $codec-pro-regular;
  src: url($font-codec-pro-path + '/CodecPro-Regular.woff2') format('woff2'),
    url($font-codec-pro-path + '/CodecPro-Regular.woff') format('woff');
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: $codec-pro-bold;
  src: url($font-codec-pro-path + '/CodecPro-Bold.woff2') format('woff2'),
    url($font-codec-pro-path + '/CodecPro-Bold.woff') format('woff');
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-display: swap;
}

/*****************  Font Functions  *********************/

@function getRemFonts($arg: 10) {
  $rem: 0.1;

  @return #{$arg * $rem}rem;
}

/******************  Font Mixins  **********************/

@mixin size($size, $height, $space) {
  font-size: getRemFonts($size);
  line-height: getRemFonts($height);
  @if ($space != 0) {
    letter-spacing: getRemFonts($space);
  }
}

@mixin font($family: $wide-regular, $size: 14, $height: 18, $space: 0) {
  font-family: $family;
  @include size($size, $height, $space);
}

@mixin font-codec($type: Regular, $size: 14, $height: 18, $space: 0) {
  font-family: 'CodecPro-#{$type}', sans-serif;
  appearance: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include size($size, $height, $space);
}
