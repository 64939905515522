@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/variables/fonts';

.container {
  position: relative;
  width: 100%;
  background-color: white;
  padding: getRem(24) getRem(16);
  border-radius: 2rem;
  @media screen and (min-width: 1024px) {
    width: 100.8rem;
    background-color: white;
    bottom: auto;
    padding: 4rem;
    border-radius: 3.2rem;
  }
}

.title {
  @include font-codec(Bold, 24, 24);
  margin-bottom: getRem(24);
  padding-right: 2rem;
  white-space: normal;
  @media screen and (min-width: 1024px) {
    @include font-codec(Bold, 32, 36);
    white-space: pre-wrap;
    margin-bottom: 2.4rem;
    padding-right: 4rem;
  }
}

.text {
  @media screen and (min-width: 1024px) {
    @include desktop_body_18;
    margin-bottom: 2.4rem;
  }

  @media screen and (max-width: 1024px) {
    font-family: 'CodecPro-Bold', sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 2.4rem;
  }
}

.field {
  margin-bottom: 0.8rem;
  @media screen and (min-width: 1024px) {
    margin-bottom: 1.2rem;
  }
}

.input {
  @media screen and (max-width: 1024px) {
    @include font-codec(Regular, 16, 20);
  }
}

.agreementContainer {
  align-items: center;
  margin-top: getRem(24);
  margin-bottom: getRem(24);
  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    margin-top: getRem(8);
    margin-bottom: getRem(12);
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.agreementContainerPromo {
  @extend .agreementContainer;
  margin-top: getRem(12);
  @media screen and (max-width: 1024px) {
    width: auto;
    margin-top: getRem(8);
  }
}

.agreement {
  @include font-codec(Regular, 16, 24);
  font-weight: normal;
  @media screen and (max-width: 1024px) {
    @include font-codec(Regular, 14, 20);
  }
  a {
    @include font-codec(Bold, 16, 24);
    font-weight: bold;
    color: $btn-blue;
    @media screen and (max-width: 1024px) {
      @include font-codec(Bold, 14, 20);
    }
  }
}

.submitButton {
  width: 100%;
  text-transform: uppercase;
  font-size: 1.4rem;
  @media screen and (min-width: 1024px) {
    font-size: 1.6rem;
  }
}

.submitButtonDisabled {
  @extend .submitButton;
  background-color: darken(#1865ff, 30%);
}

.close {
  position: absolute;
  top: 2.2rem;
  right: 1.5rem;
  width: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 99999999;
  @media screen and (min-width: 1024px) {
    top: 4.2rem;
    right: 4.2rem;
    width: 4.4rem;
  }
}
