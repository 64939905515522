@import 'src/styles/variables.scss';

.container {
  position: relative;
  border-radius: 2.4rem;
  height: 20rem;
  background-repeat: no-repeat;
  text-decoration: none;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 2.4rem;
    text-decoration: none;
    color: #000000;
    height: 100%;
  }

  @media screen and (min-width: $desktop-width) {
    position: relative;
    border-radius: 3rem;
    height: 47.5rem;
    text-decoration: none;
    overflow: hidden;

    a {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: 2.4rem;
      text-decoration: none;
      color: #000000;
      height: 100%;
    }
  }
}

.border {
  border: 1px solid #d3d4df;
}

.label {
  display: flex;
  align-items: center;
  @include font-codec(Bold, 14, 24);
  max-width: max-content;
  height: getRem(28);
  padding: 0 getRem(12);
  column-gap: getRem(14);
  border-radius: getRem(16);

  background: black;
  color: $txt-white;

  text-transform: uppercase;

  .bestseller {
    display: flex;
    align-items: center;
    @include font-codec(Bold, 10, 6);
    text-transform: lowercase;
    background-color: $pr-main;
    color: $txt-main;
    padding: getRem(4);
    margin: 0 getRem(-4);
    border-radius: getRem(16);
    .lightning {
      width: getRem(4);
      height: getRem(6);
      margin-right: getRem(4);
    }
  }
  @media screen and (min-width: $desktop-width) {
    display: flex;
    align-items: center;
    @include font-codec(Bold, 16, 24);
    max-width: max-content;
    height: getRem(40);
    padding: 0 getRem(16);
    column-gap: getRem(16);
    border-radius: getRem(16);

    background: black;
    color: $txt-white;

    text-transform: uppercase;
    .bestseller {
      display: flex;
      align-items: center;
      @include font-codec(Bold, 14, 10);
      text-transform: lowercase;
      background-color: $pr-main;
      color: $txt-main;
      padding: getRem(6);
      margin-right: getRem(-8);
      margin-left: getRem(-6);
      border-radius: getRem(16);
      .lightning {
        width: getRem(6);
        height: getRem(10);
        margin-right: getRem(4);
      }
    }
  }
}

.textWrapper {
  width: 16.6rem;

  @media screen and (min-width: $desktop-width) {
    width: 29.6rem;
    margin-top: auto;
  }
}

.title {
  @include mobile_H3;

  @media screen and (min-width: $desktop-width) {
    @include desktop_H2;
    font-size: 3.2rem;
    line-height: 3.6rem;
    white-space: pre-line;
  }
}

.dark {
  color: white;
}

.light {
  color: black;
}

.description {
  font-family: 'CodecPro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;

  @media screen and (min-width: $desktop-width) {
    font-family: 'CodecPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}

.image {
  position: absolute;
  object-fit: cover;
}
