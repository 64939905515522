@import 'src/styles/variables';

.container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 14.8rem;
  grid-row-gap: 16px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  margin-top: 0;
  margin-right: -$desktopPaddingContainer;
  margin-left: -$desktopPaddingContainer;
  @media screen and (max-width: 1024px) {
    margin: 0;
    display: block;
    animation: none;
  }
}

.elem {
  margin-right: 9rem;

  @media screen and (max-width: 1024px) {
    padding: 1.6rem 0;
    margin-top: 1.6rem;
    border-bottom: 0.1rem solid #d4d4d4;
    margin-right: 0;

    &:first-child {
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.marquee {
  height: getRem(250);
}

.title {
  font-family: 'CodecPro-Bold', sans-serif;
  font-size: getRem(140);
  line-height: getRem(120);
  white-space: nowrap;
  letter-spacing: -0.03em;

  @media screen and (max-width: 1024px) {
    font-size: 8rem;
    line-height: 6.9rem;
  }
}

.description {
  font-family: 'CodecPro-Bold', sans-serif;
  font-size: getRem(32);
  line-height: getRem(36);
  max-width: 42rem;
  width: auto;
  white-space: pre-wrap;

  @media screen and (max-width: 1024px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
    margin-top: 0.8rem;
    max-width: none;
  }
}

@keyframes moving {
  0% {
    transform: translate(0%);
  }
  100% {
    transform: translate(-33.333333%);
  }
}
