@import 'src/styles/variables.scss';

.container {
  height: 45.2rem;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: 1024px) {
    height: getRem(358);
  }
}

.cover {
  width: 100%;
  overflow: hidden;
  border-radius: getRem(24);
  margin-bottom: 1.6rem;

  video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: getRem(24);
    object-fit: cover;
    object-position: center;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 45.2rem;
  padding: getRem(12);

  @media screen and (max-width: 1024px) {
    height: getRem(358);
  }
}

.info,
.play {
  width: fit-content;
  padding: getRem(8) getRem(12);
  background-color: #ffffff;
  border: none;
  border-radius: getRem(16);
}

.info {
  display: flex;
  flex-direction: column;
  gap: getRem(4);
}

.play {
  @include font-codec(Bold, 16, 16);

  display: flex;
  align-items: center;
  gap: getRem(8);
  font-size: getRem(16);
  line-height: getRem(16);
  text-transform: uppercase;
  color: #000000;

  @media screen and (max-width: 1024px) {
    @include font-codec(Bold, 14, 14);
  }
}

.name {
  @include text-bold-s();
}

.specialty {
  text-transform: lowercase;
  @media screen and (min-width: 1024px) {
    @include font-codec(Regular, 16, 20);
  }
  @media screen and (max-width: 1024px) {
    @include font-codec(Regular, 14, 18);
  }
}

//Стикеры
.sticker {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.start {
  // background-image: url('~/public/images/fire.svg');
  width: 16rem;
  height: 18rem;
  left: 0.5rem;
  top: -8rem;
  @media screen and (max-width: 1024px) {
    width: 12.5rem;
    height: 12.5rem;
    left: 1.5rem;
    top: -5.5rem;
  }
}

.escolaOnline {
  // background-image: url('~/public/images/fire.svg');
  width: 20.4rem;
  height: 13.2rem;
  left: -8rem;
  bottom: 13.2rem;
  @media screen and (max-width: 1024px) {
    width: 15rem;
    height: 9.2rem;
    left: -3.9rem;
    bottom: 13.2rem;
  }
}

.treeD {
  // background-image: url('~/public/images/fire.svg');
  width: 13.5rem;
  height: 13.5rem;
  right: 2.2rem;
  bottom: 11.7rem;
  @media screen and (max-width: 1024px) {
    width: 11.5rem;
    height: 11.5rem;
    right: 2.2rem;
    bottom: 11.7rem;
  }
}

.fire {
  // background-image: url('~/public/images/fire.svg');
  width: 10.2rem;
  height: 13rem;
  right: 1.5rem;
  bottom: 7.8rem;
  @media screen and (max-width: 1024px) {
    width: 8.7rem;
    height: 11rem;
    right: 1.5rem;
    bottom: 7.8rem;
  }
}

.arrow {
  // background-image: url('~/public/images/fire.svg');
  width: 13rem;
  height: 13rem;
  top: -5rem;
  right: -2rem;
  @media screen and (max-width: 1024px) {
    width: 11rem;
    height: 11rem;
    top: -4rem;
    right: -1.5rem;
  }
}

.sql {
  // background-image: url('~/public/images/fire.svg');
  width: 14rem;
  height: 8.1rem;
  bottom: 17rem;
  left: -4.8rem;
  @media screen and (max-width: 1024px) {
    width: 14rem;
    height: 8.1rem;
    bottom: 17rem;
    left: -4.8rem;
  }
}

.ellipses {
  // background-image: url('~/public/images/fire.svg');
  width: 11.8rem;
  height: 11.8rem;
  bottom: 7rem;
  right: -2.6rem;
  @media screen and (max-width: 1024px) {
    width: 9.8rem;
    height: 9.8rem;
    bottom: 8rem;
    right: -1.6rem;
  }
}
