@import 'src/styles/variables';

.container {
  position: relative;
  border-radius: 2.4rem;
  min-height: 47.2rem;
  background-repeat: no-repeat;
  text-decoration: none;
  overflow: hidden;
  background-size: cover;
  background-position: center;

  a {
    display: flex;
    flex-direction: column;

    width: 100%;

    text-decoration: none;
    color: #000000;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: 33.3rem;
    margin-top: 1.6rem;
    a {
      height: inherit;
    }
  }

  @media screen and (max-width: 778px) {
    width: 100%;
    max-height: 400px;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    max-height: unset;
    min-height: unset;
  }
}

.img-container {
  width: 100%;
  min-height: 24.4rem;
  background: grey no-repeat;
  object-fit: cover;
  @media screen and (max-width: 1024px) {
    min-height: 17.6rem;
  }
  @media screen and (max-width: 480px) {
    height: 17rem;
    object-fit: cover;
  }
}

.mentor-name {
  display: block;
  font-family: 'CodecPro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #000000;
  background: #fff;
  max-width: 121px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 21rem;
  min-height: 21rem;
  border: 1px solid #d3d4df;
  border-radius: 0 0 2.4rem 2.4rem;
  border-top: none;
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;
  @media screen and (max-width: 1024px) {
    padding: 12px 24px 20px 24px;
    height: 152px;
    min-height: 15.2rem;
  }
  @media screen and (max-width: 576px) {
    min-height: unset;
    height: 100%;
  }
}

.border {
  border: 1px solid #d3d4df;
}

.label {
  border-radius: 1.6rem;
  padding: 1rem 1.6rem 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 91px;
  height: 4rem;
  width: max-content;
  background: #000;
  color: #ffffff;

  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  margin-bottom: 0.8rem;

  @media screen and (max-width: 1024px) {
    min-width: max-content;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.4rem;
    line-height: 2.2rem;
    width: 84px;
    height: 40px;
    margin-bottom: 8px;
  }
}

.text-wrapper {
  font-family: 'CodecPro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #000000;
  width: 100%;
}

.date-container {
  display: flex;
  justify-content: space-between;
  margin: auto 0 0;

  span {
    font-family: 'CodecPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #000000;
  }
  @media screen and (max-width: 576px) {
    span {
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  }
}

.title {
  font-family: 'CodecPro-Bold', sans-serif;
  font-style: normal;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #000000;
  word-break: break-word;

  @media screen and (max-width: 1619px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1232px) {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  @media screen and (max-width: 1149px) {
    font-size: 2rem;
    line-height: 2.6rem;
  }
  @media screen and (max-width: 1053px) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  @media screen and (max-width: 576px) {
    max-width: 100%;
    margin-bottom: 5px;
  }
}

.light {
  color: #fff;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
