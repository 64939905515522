@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.container {
  display: flex;
  gap: getRem(40);
  height: 100%;
  padding: getRem(40);
  background-color: white;
  border-radius: getRem(32);
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    gap: getRem(24);
    padding: getRem(24) getRem(16);
    border-radius: getRem(32) getRem(32) 0 0;
  }
}

.cover {
  position: relative;
  flex-shrink: 0;
  width: getRem(376);
  height: getRem(648);
  background: black;
  border-radius: 3.2rem;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: 1023px) {
    width: getRem(303);
    height: getRem(428);
  }
}

.controls {
  position: absolute;
  left: getRem(43);
  bottom: getRem(40);
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    left: getRem(15);
    bottom: getRem(15);
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: getRem(561);

  @media screen and (max-width: 1023px) {
    width: auto;
  }
}

.content {
  height: getRem(544);
  padding-right: getRem(56);
  @include text-regular-s();

  max-height: 85%;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    max-height: getRem(294);
    padding-right: getRem(40);
    overflow: visible;
  }
}

.name {
  @include text-bold-s();

  margin-bottom: getRem(4);
}

.specialty {
  @include font-codec(bold, 16, 20);

  margin-bottom: getRem(16);

  .gray {
    color: $txt-add;
  }

  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 14, 18);
    padding-right: getRem(40);
    margin-bottom: getRem(16);
  }
}

.rating {
  margin-bottom: getRem(25);

  @media screen and (max-width: 1023px) {
    margin-bottom: 0;
  }
}

.title-slide-inner {
  position: sticky;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1023px) {
  }
}

.scroll-wrapper {
  display: flex;
  gap: getRem(40);

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    gap: getRem(24);
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
