@import './src/styles/mixins';
.title {
  @include title-L;
  width: 56.8rem;
  white-space: pre-line;
  @media screen and (max-width: 1024px) {
    @include title-M;
    width: auto;
    white-space: normal;
  }
}

.description {
  margin-left: 31.8rem;
  width: 49.6rem;
  white-space: pre-line;
  font-style: normal;
  font-family: 'CodecPro-Bold', sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: 1024px) {
    margin-left: auto;
    margin-top: 1.6rem;
    width: auto;
    font-family: 'CodecPro', sans-serif;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    white-space: normal;
  }
}

.wrapperProduct {
  margin-top: 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1024px) {
    display: block;
    margin-top: 2.4rem;
  }
}

.wrapperProductCard {
  width: calc(25% - 0.8rem);
  background-position: initial;
  @media screen and (max-width: 1024px) {
    margin-top: 1.6rem;
    width: auto;

    &:first-child {
      margin-top: 0;
    }
  }
}

.wrapperButton {
  margin-top: 4.8rem;
  width: 100%;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    margin-top: 1.6rem;
  }
}

.header {
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    display: block;
    margin: 0;
    padding: 0;
  }
}
