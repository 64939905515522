@import './variables/colors';
@import './variables/fonts';
@import './src/styles/mixins';

@mixin fontBase() {
  font-family: 'CodecPro', sans-serif;
  font-style: normal;
}

@mixin fontBold() {
  font-family: 'CodecPro-Bold', sans-serif;
  font-style: normal;
}

@mixin mobile_H1($color: #000) {
  @include fontBold;
  font-size: 4.2rem;
  line-height: 4.2rem;
  color: $color;
}

@mixin mobile_H2($color: #000) {
  @include fontBold;
  font-size: 4rem;
  line-height: 4rem;
  color: $color;
}

@mixin mobile_H3($color: #000) {
  @include fontBold;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: $color;
}

@mixin desktop_H1($color: #000) {
  @include fontBold;
  font-size: 6.4rem;
  line-height: 6.4rem;
  color: $color;
}

@mixin desktop_H2($color: #000) {
  @include fontBold;
  font-size: 4rem;
  line-height: 4rem;
  color: $color;
}

@mixin desktop_H3($color: #000) {
  @include fontBold;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: $color;
}

@mixin desktop_H4($color: #000) {
  @include fontBold;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: $color;
}

@mixin desktop_control_16($color: #000) {
  @include fontBold;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color;
}

@mixin desktop_caption_16($color: #000) {
  @include fontBase;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color;
}

@mixin desktop_caption_18($color: #000) {
  @include fontBase;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: $color;
}

@mixin desktop_body_18($color: #000) {
  @include fontBold;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: $color;
}

@mixin mobile_body_16($color: #000) {
  @include fontBase;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2rem;
  color: $color;
}

@mixin mobile_body_18($color: #000) {
  @include fontBase;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2rem;
  color: $color;
}

@mixin container() {
  width: 152rem;
  margin-left: auto;
  margin-right: auto;
}

$desktop-width: 1025px;
$mobile-width-only: 1024px;

$desktopPaddingWrapper: getRem(56) getRem(40);
$mobilePaddingWrapper: getRem(40) getRem(16);

$mobilePaddingContainer: getRem(16);
$desktopPaddingContainer: 4rem;
$courseDesktopPaddingContainer: getRem(40);
$mainBackgroundColor: #1865ff;
$secondBackgroundColor: #7896ff;
$transitionBase: 0.3s;
$heightHeader: 6.4rem;
$heightHeaderThanksPageMobile: getRem(84);
$heightHeaderThanksPageDesktop: getRem(116);
$heightSaleInfo: 10rem;
$heightHeaderMain: ($heightHeader + $heightSaleInfo);

$colorGrey: #d3d4df;
$colorAccentBlueMain: #1865ff;
