@import 'src/styles/variables';

.wrapper {
  margin-bottom: 0 !important;
}

.title {
  margin-bottom: getRem(32);
}

.swiperContainer {
  width: 100%;
  overflow: visible;
}

.swiperContainer .swiperSlide {
  width: getRem(1500);
  margin-right: getRem(16);
  margin-bottom: 0;
  @media screen and (max-width: 1024px) {
    width: getRem(375-16-16);
    margin-right: getRem(16);
  }
}
