@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include standard-block-margin();
}

.containerHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4.6rem;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    margin-bottom: 1.2rem;
  }
}

.containerWebinars {
  position: relative;
}

.listWebinars {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4.8rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
}

.title {
  position: relative;
  @include title-M;
  @media screen and (max-width: 1024px) {
    font-style: normal;
    font-size: 4rem;
    line-height: 4rem;
    color: #000000;
  }
}

.description {
  font-family: 'CodecPro-Bold', sans-serif;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #000000;
  max-width: 49.6rem;
  margin-right: 12.8rem;

  @media screen and (max-width: 1024px) {
    margin-top: 42px;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  @media screen and (max-width: 576px) {
    margin-top: 42px;
    font-size: 1.6rem;
    line-height: 2rem;
    max-width: 100%;
    width: 100%;
  }
}

.banner {
  margin-top: 1.6rem;

  &:first-child {
    margin-top: 0;
  }
}

.button {
  margin-top: 1.6rem;
  height: 6.4rem;
  width: 100%;
  background-color: #1865ff;
  font-weight: normal;
  text-transform: uppercase;
}

.free {
  position: absolute;
  top: 1.4rem;
  right: -7rem;
  width: 20rem;
  height: 7.1rem;
  background-size: cover;

  @media screen and (max-width: 1024px) {
    top: 1.3rem;
    left: 5.4rem;
    transform: rotate(-3deg);
  }
}
