@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.container {
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: getRem(64);
  @media screen and (max-width: 1024px) {
    margin-bottom: getRem(40);
  }
}

.counter {
  margin-bottom: getRem(8);
  font-size: getRem(16);
  line-height: getRem(20);
}

.title {
  @media screen and (min-width: 1024px) {
    @include desktop_H1;
  }
  @media screen and (max-width: 1024px) {
    @include mobile_H2;
  }
}

.arrows {
  position: relative;
  display: flex;
  align-self: flex-end;
}

.swiperButton {
  width: 6.4rem;
  height: 6.4rem;
  cursor: pointer;
}

.swiperButtonPrev {
  transform: rotate(180deg);
  margin-right: 0.8rem;
}

.swiperContainer {
  width: 100vw;

  @media screen and (min-width: $desktop-width) {
    padding-right: getRem(40);
  }
}

.swiperContainer .slide {
  width: 32rem;
  margin-right: getRem(64);
  @media screen and (max-width: 1024px) {
    width: 26.2rem;
    margin-right: getRem(16);
  }
}
