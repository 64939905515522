@import 'src/styles/variables/fonts';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  position: relative;
  &::before {
    position: absolute;
    display: none;
    right: -40rem;
    content: '';
    width: 80rem;
    height: 80rem;
    z-index: -99999;
    transform: rotate(20deg) scaleX(2) scaleY(1.5);
  }

  div {
    white-space: pre-wrap;
  }
  @media screen and (max-width: 1024px) {
    position: relative;
    display: block;
    padding: 0;
    &::before {
      top: 60rem;
      right: getRem(-254);
      width: 40rem;
      height: 40rem;
    }
  }
}

.yellow::before {
  display: block;
  background: rgb(255, 161, 20);
  background: radial-gradient(
    circle,
    rgba(255, 161, 20, 0.8) 0%,
    rgba(255, 246, 40, 0.2) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.green::before {
  display: block;
  background: rgb(89, 234, 164);
  background: radial-gradient(
    circle,
    rgba(89, 234, 164, 0.8) 0%,
    rgba(0, 86, 162, 0.2) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.violet::before {
  display: block;
  background: rgb(179, 51, 222);
  background: radial-gradient(
    circle,
    rgba(179, 51, 222, 0.8) 0%,
    rgba(201, 48, 121, 0.2) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.blue::before {
  display: block;
  background: rgb(24, 101, 255);
  background: radial-gradient(
    circle,
    rgba(24, 101, 255, 0.8) 0%,
    rgba(215, 184, 255, 0.4) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.imageContainer {
  position: relative;
  display: flex;
  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.message {
  position: absolute;
  z-index: 10;
  @media screen and (max-width: 1024px) {
    position: static;
    margin-bottom: getRem(176);
  }
}

.image {
  width: getRem(370);
  height: auto;
  margin-top: getRem(60);
  margin-left: getRem(300);
  object-fit: contain;
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 10.3rem;
    left: 12rem;
    z-index: -10;
    width: getRem(220);
    margin: 0;
  }
}

.itemContent {
  margin-right: getRem(128);
  width: getRem(484);
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    padding: 0;
    width: auto;
    margin-right: 0;
  }
}

.points {
  display: flex;
  flex-direction: column;
  row-gap: getRem(24);
  @media screen and (max-width: 1024px) {
    row-gap: getRem(16);
  }
}

.point {
  position: relative;
  @include font-codec(Bold, 32, 36);
  margin-left: getRem(28);

  &::before {
    position: absolute;
    top: 1rem;
    left: getRem(-28);
    border-radius: 50%;
    background-color: #1865ff;
    content: '';
    width: getRem(12);
    height: getRem(12);
  }
  @media screen and (max-width: 1024px) {
    @include font-codec(Bold, 24, 24);
    &::before {
      top: 0.9rem;
    }
  }
}

.mobile {
  display: inline-block;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}

.desktop {
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: inline-block;
  }
}
