@import 'src/styles/variables';
@import 'src/styles/mixins';

.title {
  @media screen and (min-width: 1024px) {
    @include desktop_H1;
    max-width: 54rem;
  }
  @media screen and (max-width: 1024px) {
    @include title-M;
  }
}

.wrapperElements {
  margin-top: 4.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5.2rem;
  grid-row-gap: 3.2rem;
  @media screen and (max-width: 1024px) {
    margin-top: 2.9rem;
    display: block;
  }
}
