@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5.6rem 0 4.8rem;
  @media screen and (max-width: 1024px) {
    padding: 0;
    padding-top: getRem(40);
    height: 100%;
  }
}

.title {
  @include desktop_H1;
}

.swiperButton {
  width: 6.4rem;
  height: 6.4rem;
  background-image: url('~/public/images/arrow-circle.svg');
  cursor: pointer;
}

.swiperContainer {
  width: 100vw;
  @media screen and (min-width: 1024px) {
    :global {
      .swiper-slide-active {
        transform: none !important;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    height: 100%;
    overflow: visible;
  }
}

.swiperContainer .slide {
  display: flex;
  justify-content: center;
  width: 100.8rem;
  height: 75.6rem;
  transform: scale(0.78);
  margin-right: 10rem;
  @media screen and (max-width: 1024px) {
    display: block;
    width: 100%;
    height: 100%;
    transform: none;
    margin-right: 0;
  }
}

.activeSlide {
  transform: none;
}

.close {
  position: fixed;
  top: getRem(95);
  right: getRem(310);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 99999999;

  @media screen and (max-width: 1024px) {
    right: getRem(15);
    top: getRem(65);

    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
