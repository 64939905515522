@import 'src/styles/variables';

.wrapper {
  display: flex;
  transition: opacity 300ms ease-in-out;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.image {
  width: 75.2rem;
  min-width: 75.2rem;
  height: 75.2rem;
  object-fit: contain;
  @media screen and (max-width: 1024px) {
    width: 34.3rem;
    min-width: 34.3rem;
    height: 34.8rem;
  }
}

.container {
  margin-left: 14.4rem;
  @media screen and (max-width: 1024px) {
    margin-top: 1.2rem;
    margin-left: 0;
  }
}

.title {
  text-transform: lowercase;
  margin-top: getRem(80);
  margin-bottom: getRem(40);
}

.name {
  @include font-codec(Bold, 24, 32);
  margin-bottom: getRem(4);
  @media screen and (max-width: 1024px) {
    @include font-codec(Bold, 18, 24);
  }
}

.profession {
  @include font-codec(Regular, 16, 24);
  color: $dsn-main;
  white-space: pre-wrap;
  text-decoration: none;
  margin-bottom: getRem(4);

  @media screen and (max-width: 1024px) {
    @include font-codec(Regular, 14, 20);
  }
}

.position {
  @include font-codec(Regular, 18, 24);
  white-space: pre-wrap;
  margin-bottom: getRem(24);

  @media screen and (max-width: 1024px) {
    @include font-codec(Regular, 16, 20);
    margin-bottom: getRem(16);
  }
}

.location {
  @media screen and (min-width: 1024px) {
    @include desktop_caption_18;
    margin-top: 0.3rem;
  }

  @media screen and (max-width: 1024px) {
    @include mobile_body_16;
    margin-top: 0.4rem;
  }
}

.logo {
  object-fit: contain;
  height: getRem(80);
  width: auto;
  max-width: getRem(200);
  margin-bottom: getRem(40);

  @media screen and (max-width: 1024px) {
    height: getRem(64);
    max-width: getRem(150);
    margin-bottom: getRem(24);
  }
}

.comment {
  position: relative;

  & .quoteStart {
    width: getRem(17);
    height: getRem(20);
    color: $mainBackgroundColor;
    position: absolute;
    top: 0;
    left: getRem(-21);
    object-fit: contain;
  }

  & .quoteEnd {
    position: relative;
    display: inline-block;
    content: url('~/public/images/quote-mark.svg');
    width: getRem(17);
    height: getRem(15);
    top: getRem(-5);
    object-fit: contain;
  }

  @include font-codec(Bold, 24, 32);
  max-width: getRem(532);
  white-space: pre-wrap;

  @media screen and (max-width: 1024px) {
    @include font-codec(Bold, 18, 24);
    padding-left: 2rem;

    & .quoteStart {
      width: getRem(15);
      left: 0;
    }

    & .quoteEnd {
      width: getRem(15);
      top: getRem(-2);
    }
  }
}
