@import 'src/styles/variables';

.container {
  display: flex;
  padding: 0.2rem 0;

  :global {
    .ReactCollapse--collapse {
      transition: height 500ms;
    }
  }
}

.text {
  margin-top: 2.2rem;
}

.icon {
  width: 9.2rem;
  height: 9.2rem;
  margin-right: 3.6rem;
}

.title {
  width: 18rem;
  font-family: 'CodecPro-Bold', sans-serif;
  font-style: normal;
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.arrow {
  width: 1.2rem;
  height: 0.6rem;
  margin: 0.6rem 0.4rem 0 auto;
  object-fit: cover;
}

.description {
  padding: 1.3rem 0 2.2rem;
  @include desktop_caption_18;
  white-space: pre-wrap;
}
