@import 'src/styles/variables';

.container {
  position: relative;
  height: getRem(640);
  background-color: $dsn-main;
  background-image: url('~/public/images/banner-gradient/main-gradient-mobile.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top;
  padding: getRem(151) $mobilePaddingContainer 0;
  margin-top: -$heightHeaderMain;
  overflow: hidden;
  z-index: 10;
  hr {
    border: none;
    height: 0.1rem;
    background: #ffffff;
    opacity: 0.23;
    margin-top: 2.4rem;
  }
  @media screen and (min-width: 1024px) {
    padding: $heightHeaderMain $desktopPaddingContainer 0;
    height: getRem(900);
    background-image: url('~/public/images/banner-gradient/main-gradient-desktop.svg');
    background-size: contain;
    background-position: right;
  }
}

.gradient {
  z-index: -1;
}

.logo {
  width: getRem(342);
  height: getRem(40);
  object-fit: cover;
  @media screen and (min-width: 1024px) {
    width: getRem(1520);
    height: getRem(178);
  }
}

.signature {
  @include font-codec(Bold, 28, 28);
  width: 26rem;
  margin-top: getRem(223);
  color: white;
  @media screen and (min-width: 1024px) {
    width: 36rem;
    margin-top: getRem(216);
    @include font-codec(Bold, 40, 40);
  }
}

.container .wrapperButton {
  @include font-codec(Bold, 14, 20);
  margin-top: getRem(16);
  padding: getRem(18) getRem(40);
  height: auto;
  width: auto;
  text-transform: uppercase;
  @media screen and (min-width: 1024px) {
    @include font-codec(Bold, 16, 24);
    padding: getRem(21) getRem(40) getRem(19);
    margin-top: getRem(24);
  }
}

.illustration {
  position: absolute;
  width: getRem(307);
  height: getRem(219);
  right: getRem(16);
  top: getRem(186);
  object-fit: cover;
  @media screen and (min-width: 1024px) {
    width: getRem(624);
    height: getRem(445);
    right: getRem(66);
    top: auto;
    bottom: getRem(148);
  }
}
